<template>
  <v-container fluid class="pa-0">
    <shop-header :title="title" :description="description"></shop-header>
    <v-divider></v-divider>
    <v-breadcrumbs v-if="content" :items="breadcrumbs"> </v-breadcrumbs>

    <v-list>
      <template v-for="(item, index) in items">
        <v-divider
          v-if="index > 0 && index < items.length"
          :inset="item.inset"
          :key="index"
        ></v-divider>
        <shop-item
          v-if="item.type === 'item'"
          :key="item.shop_item_title"
          :title="item.shop_item_title"
          :id="$route.params.id"
          :image="item.shop_item_image"
          :description="item.shop_item_description"
          :price="item.shop_item_price"
          :productId="item.id"
        ></shop-item>
        <shop-group
          v-else
          :key="item.shop_item_title"
          :title="item.shop_item_title"
          :id="$route.params.id"
          :groupId="item.id"
          :image="item.shop_item_image"
          :description="item.shop_item_description"
        ></shop-group>
      </template>
    </v-list>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ShopGroup from "@/components/shop/ShopGroup";
import ShopItem from "@/components/shop/ShopItem";
import ShopHeader from "@/components/shop/ShopHeader";

export default {
  components: {
    ShopGroup,
    ShopItem,
    ShopHeader,
  },
  methods: {
    ...mapActions("shop", [
      "getInfo",
      "getContent",
      "setActiveGroup",
      "transmitOrder",
      "setActiveItemListData",
      "setPurchaseFlowCache",
      "modifyPurchaseFlowSubitemSelected",
      "addToCartList",
      "modifyCartItemQuantity",
      "addPurchaseFlowItemToCart",
      "resetCartList",
      "reduceCartListDuplicates",
    ]),
    initData() {
      this.getInfo(this.$route.params.id);
      this.getContent(this.$route.params.id);
    },
    getAllGroups() {
      return this.getGroupsFromItems({
        items: this.content.retail_items,
      });
    },
    getGroupsFromItems({ items }) {
      let groups = [];
      items.forEach((element) => {
        if (element.type === "group") {
          groups.push(element);
          if (element.retail_items && element.retail_items.length) {
            groups = [
              ...groups,
              ...this.getGroupsFromItems({
                items: element.retail_items,
              }),
            ];
          }
        }
      });

      return groups;
    },
  },
  computed: {
    ...mapState("shop", {
      statusContent: (state) => state.statusContent,
      info: (state) => state.info,
      content: (state) => state.content,
      activeGroup: (state) => state.activeGroup,
      parentGroup: (state) => state.parentGroup,
      rootTemplateData: (state) => state.rootTemplateData,
      title: (state) => state.title,
      description: (state) => state.description,
    }),
    groups() {
      return this.getAllGroups();
    },
    currentGroup() {
      let groups = this.$route.query.groups;
      if (!groups) {
        return this.content;
      }

      if (typeof groups !== "object") {
        groups = [groups];
      }

      return this.groups.filter((element) => {
        return element.id === groups[groups.length - 1];
      })[0];
    },
    items() {
      return this.currentGroup.retail_items;
    },
    breadcrumbs() {
      let { groups = [] } = this.$route.query;

      if (typeof groups !== "object") {
        groups = [groups];
      }

      const items = [
        {
          exact: true,
          text: this.title,
          to: {
            name: "shop",
            params: { id: this.$route.params.id },
            query: { ...this.$route.query, groups: undefined },
          },
        },
      ];

      const breadcrumbGroups = [];
      groups.forEach((id) => {
        const item = this.groups.filter((item) => {
          return item.id === id;
        })[0];
        breadcrumbGroups.push(item.id);
        items.push({
          exact: true,
          to: {
            name: "shop",
            params: { id: this.$route.params.id },
            query: { ...this.$route.query, groups: [...breadcrumbGroups] },
          },
          text: item.shop_item_title,
        });
      });

      return items;
    },
  },
  created() {
    this.initData();
  },
  watch: {
    "$route.params.id"(val) {
      this.initData();
    },
  },
};
</script>
